import { TabWrapper, TagWrapper } from '@/components/Shared';
import Divider from '@/components/Shared/Divider';
import { GetAllHotTagsParams } from '@/constants/defaultParams';
import { Layouts_Enum, useGetAllTagsQuery } from '@/generated/graphql';
import { IHomeSection } from '@/interfaces/category';
import format from '@/utils/format';

const HomeLeftSection = ({ data }: IHomeSection) => {
  const { data: TagData } = useGetAllTagsQuery({
    variables: GetAllHotTagsParams,
  });
  return (
    <div className="lg:mr-3 lg:pr-8 flex flex-col lg:border-r-[1px] border-[#b8b8b8]">
      {data?.map((category, index) => {
        const dataPost = format.formatDataPost(category.allPosts);

        const dataCategory = format.formatDataCategory(
          category?.children,
          category?.pathFullSlug?.fullSlug || '',
          category?.name || '',
        );

        return (
          <>
            <TabWrapper
              key={index}
              layout={category?.layoutAtHome || Layouts_Enum.OneLargeFourSmall}
              data={dataPost}
              category={dataCategory}
            />
            {index === data?.length - 1 ? null : <Divider />}
          </>
        );
      })}
      <TagWrapper data={TagData} notFullTag />
    </div>
  );
};

export default HomeLeftSection;
